<template>
  <div class="user-datail modal fade" id="userListDetail" tabindex="-1" role="dialog" aria-labelledby="userListModalLabel">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="returnPath"><span aria-hidden="true">&times;</span></button>
          <h4 class="modal-title" id="userListModalLabel">人员详情</h4>
        </div>
        <div class="modal-body">
          <ul class="list-group list-group-unbordered">
            <li class="list-group-item">
              <b>姓名</b> <span class="pull-right">{{ user.Name }}</span>
            </li>
            <li class="list-group-item">
              <b>性别</b> <span class="pull-right"><Tag :color="$common.genderColor[user.IsMale?'1':'0']">{{ $commom.gender[user.IsMale?'1':'0'] }}</Tag></span>
            </li>
            <li class="list-group-item">
              <b>生日</b> <span class="pull-right">{{ $moment(user.Birthday).format('YYYY-MM-DD') }}</span>
            </li>
            <li class="list-group-item">
              <b>用户登录名</b> <span class="pull-right">{{ user.Username }}</span>
            </li>
            <li class="list-group-item">
              <b>用户权限</b> <span class="pull-right">
                <Tag v-for="(perm,permIdx) in getPermissions()" :key="permIdx">{{perm}}</Tag>
              </span>
            </li>
            <li class="list-group-item">
              <b>账号状态</b> <span class="label pull-right" :class="{ 'label-success': user.IsEnable, 'label-danger': !user.IsEnable }">{{ getEnableText() }}</span>
            </li>
            <li class="list-group-item">
              <b>身份证号</b> <span class="pull-right">{{ showIdNumber(user.IdNumber) }}</span>
            </li>
            <li class="list-group-item">
              <b>手机号码</b> <span class="pull-right">{{ user.MobilePhoneNumber }}</span>
            </li>
            <li class="list-group-item">
              <b>IC卡编码</b> <span class="pull-right">{{ user.IcCardId }}</span>
            </li>
            <li class="list-group-item">
              <b>微信openId</b> <span class="pull-right">{{ user.Wechat }}</span>
            </li>
            <li class="list-group-item">
              <b>邮箱</b> <span class="pull-right">{{ user.Email }}</span>
            </li>
            <li class="list-group-item">
              <b>注册日期</b> <span class="pull-right">{{ $moment(user.CreationTime).format('YYYY-MM-DD') }}</span>
            </li>
          </ul>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default pull-left" data-dismiss="modal" @click="returnPath">关闭</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'user-detail',
  props: ['data'],
  data () {
    return {
      userId: this.data,
      user: {}
    }
  },
  mounted () {
  },
  methods: {
    returnPath () {
      this.$router.back()
    },
    getPermissions () {
      let perms = []
      if (this.user.Permissions) {
        this.user.Permissions.forEach((p) => {
          perms.push(this.$common.permissions[p.toString()])
        })
      }
      return perms
    },
    getEnableText () {
      var text = this.user.IsEnable ? '已启用' : '已禁用'
      return text
    },
    showIdNumber (id) {
      let idnumber = ''
      if (id) {
        if (id.length === 18) {
          // idnumber = id.substr(0, 6) + '****' + id.substr(14, 18)
          idnumber = id.substr(0, 10) + '****'
        } else if (id.length === 15) {
          idnumber = id.substr(0, 8) + '****'
        }
      }
      return idnumber
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
